import React from 'react'
import { connect } from 'react-redux'

import NoteNavBar from "./navbars/CommentNavBar"

import { IMAGE_BASE_URL } from '../commons'

const Note = (props) => {

    const { currentTravelbook, stepId, note, observer, viewSlider } = props

    const mediaBase = IMAGE_BASE_URL + '/' + currentTravelbook.slug + '/' + note.mediatype

    // enregistrement de l'observer uniquement après la création de la première note
    React.useEffect(() => {
        if (!viewSlider) {
            // enregistrement du div à observer pour la note
            const selector = `#note-${note.id}`
            observer.observe(document.querySelector(selector))
        }
        // eslint-disable-next-line
    }, [])

    // affichage de la description
    const displayDescription = (position, note) => {
        if (note.description) {
            if ((position === 'first' && note.textfirst) || (position === 'last' && (!note.textfirst))) {
                return (<div className="note-desc" dangerouslySetInnerHTML={{ __html: note.description }} />)
            }
            else return ('')
        }
        else return ('')
    }


    // affichage de la legende du media
    const displayCaption = (note) => {
        if (note.caption) return (<p className="note-caption">{note.caption}</p>)
        else return ('')
    }


    // affichage du media et sa legende
    const displayMedia = (note) => {
        if (note.mediatype && note.filename) {
            switch (note.mediatype) {
                case 'image':
                    return (
                        <>
                            <img className="note-image" loading="lazy" src={`${mediaBase}/${note.filename}`} alt={note.caption} />
                            {displayCaption(note)}
                        </>
                    )

                case 'video':
                    return (
                        <>
                            <video className="note-video" controls src={`${mediaBase}/${note.filename}`} />
                            {displayCaption(note)}
                        </>
                    )

                default:
                    return ('')
            }
        }
        else return ('')
    }


    // affichage de la note (description et media)
    const displayNote = (note) => {

        return (
            <>
                {displayDescription('first', note)}
                <div className="note-media">
                    {displayMedia(note)}
                </div>
                {displayDescription('last', note)}
            </>
        )
    }


    return (
        <>
            <div id={`note-${note.id}`} className="note">
                {displayNote(note)}
            </div>
            <NoteNavBar note={note} stepId={stepId}></NoteNavBar>
        </>
    )
}


// Redux: mapping des props depuis le store
const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        steps: state.steps,
        openMain: state.openMain,
        viewSlider: state.viewSlider
    }
}

export default connect(mapStateToProps)(Note)