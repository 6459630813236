import React from 'react'
import { Link } from "@reach/router"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// action redux view slider (slider / scrollList)
import { setViewSlider } from '../../actions/index'

// action redux état de la carte
import { setMapReady } from '../../actions/index'

// action redux nom du menu
import { setMenuName } from '../../actions/index'

import DropDownMenu from "../navigation/DropDownMenu";


// affichage du nom du carnet courant

const TravelbookNavBar = (props) => {

    // carnet à éditer (update / delete) et état des menus
    const { currentTravelbook, steps, viewSlider, setViewSlider, setMapReady, menuName, setMenuName } = props

    // switch viewer 
    const switchViewer = (e) => {
        e.preventDefault()
        setViewSlider(!viewSlider) // bascule le mode d'affichage des notes entre slider et scrollList
    }

    // ajout du bouton viewer toggle
    const displayToggleButton = () => {
        if (viewSlider) return ('fas fa-toggle-on')
        else return ('fas fa-toggle-off')
    }

    const leaveTravelbook = () => {
        // redirection vers la page d'accueil
        setMapReady(false)
    }

    // ajout de la première étape 
    const displayNavItem = (onClick, link, label, steps) => {
        if (steps.length > 0) return ('')
        // affichage de l'item de menu 
        else return (<li><button type="button" className="link-button" onClick={(e) => onClick(e, link)}>{label}</button></li>)
    }

    // mise à jour du nom de menu 
    const onClick = (e, label) => {
        if (label === menuName) setMenuName('')  // fermeture du menu suite a 2 click sur le meme item de menu
        else setMenuName(label)
    }

    return (
        <div className="travelbook-navbar-container">
            <div className="title"><Link to="/" onClick={leaveTravelbook}><span>{currentTravelbook.name}</span></Link></div>
            <div className="navbar-container">
                <button type="button" className="toggle">
                    <span className="toggle-icon" onClick={switchViewer}>
                        <i className={displayToggleButton()}></i>
                    </span>
                </button>
                <DropDownMenu>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'update-travelbook')}>Modification du carnet de voyage</button></li>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'delete-travelbook')}>Suppression du carnet de voyage</button></li>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'manage-tracks')}>Gestion des traces du carnet de voyage</button></li>
                    {displayNavItem(onClick, 'add-first-step', "Ajout de la première étape du carnet", steps)}
                </DropDownMenu>
            </div>
        </div>
    )
}

// Redux: mapping action creators - setTracks
// Mise à jour du menu carnet de voyage courant
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setViewSlider: setViewSlider,  // selection de la vue Slider / ScrollLlist
        setMapReady: setMapReady,
        setMenuName: setMenuName
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        steps: state.steps,
        viewSlider: state.viewSlider,
        menuName: state.menuName
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelbookNavBar)