
// root server
// local
//const ROOT_URL = 'http://traveldev-wp.local'   // local dev
//const ROOT_URL = 'http://travelsteps-wp.local' // local prod
//const ROOT_URL = 'http://traveldemo-wp.local'  // local demo v1.1

// www
//const ROOT_URL = 'https://traveldev-wp.touteslatitudes.fr'        // www dev
//const ROOT_URL = 'https://travelsteps-wp.touteslatitudes.fr'      // www prod
const ROOT_URL = 'https://traveldemo-wp.touteslatitudes.fr'       // www demo v1.1

//const ROOT_URL = 'https://tsdev-wp.touteslatitudes.fr'                // www prod demo v1.0

// Clé API Portail IGN
//export const IGN_API_KEY = 'bve8gatglz257harayqi0kcm' // travelsteps.touteslatitudes.fr - Expire le 04/10/2024
export const IGN_API_KEY = '530un7py7gdcgbfc6ij8zxfh' // traveldev.touteslatitudes.fr, traveldemo.touteslatitudes.fr - Expire le 05/10/2024


// repertoire assets
export const IMAGE_BASE_URL = ROOT_URL  + '/assets'

// repertoire json server
export const TRAVELSTEPS_SERVER_URL = ROOT_URL  +'/wp-admin/admin-ajax.php'

// centre de la carte par defaut
export const DEFAULT_MAPCENTER =  '47.902447, 1.903858'

// zoom par default
export const DEFAULT_ZOOM = 12

// qualité de la compression d'image
export const QUALITY_IMAGE = 0.7

// largeur maximum d'une image en pixels
export const MAXWIDTH_IMAGE = 1024

// hauteur maximum d'une image en pixels
export const MAXHEIGHT_IMAGE = 768

// Epaisseur du trait pour la trace
export const WEIGHT_TRACK = 3

// Opacité du trait pour la trace
export const OPACITY_TRACK = 0.7

// Couleur par défaut pour une trace
export const COLOR_TRACK = '#0000ff'
