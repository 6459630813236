import React, { Suspense, lazy } from "react"
import { Link } from "@reach/router"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// action redux nom du menu
import { setMenuName } from '../../actions/index'

import DropDownMenu from "../navigation/DropDownMenu";

// Formulaires des sous menus
//import Forms from "../forms/Forms"
const Forms = lazy(() => import('../forms/Forms'));


// Barre de menus de la page d'accueil
const HomeNavBar = (props) => {

    const { menuName, setMenuName } = props

    // mise à jour du nom de menu 
    const onClick = (e, label) => {
        if (label === menuName) setMenuName('')  // fermeture du menu suite a 2 click sur le meme item de menu
        else setMenuName(label)
    }

    return (
        <div className="home-navbar-container">
            <div className="title"><Link to="/">Travelsteps</Link></div>
            <DropDownMenu>
                <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'add-travelbook')}>Ajouter un carnet de voyage</button></li>
            </DropDownMenu>
            <Suspense fallback={<div>Chargement...</div>}>
                <Forms />
            </Suspense>
        </div>
    )
}

// Redux: mapping action creators - setMenuName
// Mise à jour du menu du menu
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setMenuName: setMenuName
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        menuName: state.menuName
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavBar)