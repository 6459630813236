import React from 'react';
import "./DropDownMenu.scss";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setIsActiveDU } from '../../actions/index'

import { setCurrentStep } from '../../actions/index'
import { setCurrentNote } from '../../actions/index'

// Bouton pour la barre de menu Note.
const DropUpButton = (props) => {

    const { note, stepId, currentStep, setCurrentStep, steps, setCurrentNote, isActiveDU, setIsActiveDU } = props

    const onClick = (e, note, stepId) => {

        e.preventDefault()

        // changement de note courante
        setCurrentNote(note)

        // changement d'étape courante
        // clicker sur une note peut entrainer un changement d'étape courante
        if (stepId !== currentStep.id) {
            const index = steps.findIndex(step => step.id === stepId) // index de l'étape à laquelle la note appartient
            const newStep = steps[index]
            setCurrentStep(newStep)
        }

        setIsActiveDU(!isActiveDU);
    }

    const defaultOptions = {
        iconStyle: 'menu-dark-icon',
        icon: 'fas fa-bars'            // icone menu
    }
    // mise à jour des options
    const options = { ...defaultOptions, ...props.options }
    const { iconStyle, icon } = options


    return (
        <div className="menu-container">
            <button type="button" className="menu-trigger"><span className={iconStyle} onClick={(e) => onClick(e, note, stepId)}><i className={icon}></i></span></button>
        </div>
    )
};

// Redux: mapping action creators
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setIsActiveDU: setIsActiveDU,
        setCurrentStep: setCurrentStep,
        setCurrentNote: setCurrentNote
    }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
    return {
        currentStep: state.currentStep,
        steps: state.steps,
        isActiveDU: state.isActiveDU
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropUpButton)