import './App.scss';

import React from "react"

import HomePage from './home/HomePage';
import { Router } from "@reach/router"

import TravelbookPage  from './travelbook/TravelbookPage';

function App() {

  return (
    <div>
      <Router>
          <TravelbookPage path="/:urlTravelbookSlug/:urlStepSlug" />
          <TravelbookPage path="/:urlTravelbookSlug" />
        <HomePage path="/" />
      </Router>
    </div>
  );

}

export default App;

