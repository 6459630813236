// Reducers Redux

import { combineReducers } from 'redux'

// Etat de la carte
import mapReadyReducer from './mapReadyReducer'

// Courants
import currentNoteReducer from './currentNoteReducer'
import currentStepReducer from './currentStepReducer'
import currentTrackReducer from './currentTrackReducer'
import currentTravelbookReducer from './currentTravelbookReducer'

// Listes
import travelbooksReducer from './travelbooksReducer'
import stepsReducer from './stepsReducer'
import noteIndexesReducer from './noteIndexesReducer'
import tracksReducer from './tracksReducer'

// Etape de départ
import startStepReducer from './startStepReducer'

// Sélections sur la carte
import selectedStepIdReducer from './selectedStepIdReducer'
import selectedTrackIdReducer from './selectedTrackIdReducer'
import selectedPointReducer from './selectedPointReducer'

// toggle type de vue
import viewSliderReducer from './viewSliderReducer'

// nom du menu
import menuNameReducer from './menuNameReducer'

// activité du menu Drop Up
import isActiveDUReducer from './isActiveDUReducer'


const rootReducer = combineReducers({
  mapReady: mapReadyReducer,
  currentNote: currentNoteReducer,
  currentStep: currentStepReducer,
  currentTrack: currentTrackReducer,
  currentTravelbook : currentTravelbookReducer,
  travelbooks : travelbooksReducer,
  steps : stepsReducer,
  noteIndexes : noteIndexesReducer,
  tracks : tracksReducer,
  startStep: startStepReducer,
  selectedStepId : selectedStepIdReducer,
  selectedTrackId : selectedTrackIdReducer,
  selectedPoint: selectedPointReducer,
  viewSlider: viewSliderReducer,
  menuName: menuNameReducer,
  isActiveDU: isActiveDUReducer
})

export default rootReducer