import "./DropDownMenu.scss";

import React, { useRef, useEffect } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// action redux Drop Up Menu actif
import { setIsActiveDU } from '../../actions/index'

// menu pour la barre de menu Note
const DropUpMenu = (props) => {

	const { isActiveDU, setIsActiveDU } = props

	const dropupRef = useRef(null);

	useEffect(() => {
		const pageClickEvent = (e) => {
			// If the active element exists and is clicked outside of
			if (dropupRef.current !== null && !dropupRef.current.contains(e.target)) {
				setIsActiveDU(!isActiveDU);
			}
		};

		// If the item is active (ie open) then listen for clicks
		if (isActiveDU) {
			window.addEventListener('click', pageClickEvent);
		}

		return () => {
			window.removeEventListener('click', pageClickEvent);
		}

		// eslint-disable-next-line
	}, [isActiveDU]);

	return (
		<div className="menu-container">
			<nav ref={dropupRef} className={`menu ${isActiveDU ? 'active' : 'inactive'}`}>
				<ul>
					{props.children}
				</ul>
			</nav>
		</div>
	)
}


// Redux: mapping action creators
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setIsActiveDU: setIsActiveDU
	}, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
	return {
		isActiveDU: state.isActiveDU
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DropUpMenu)