import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TravelbookListItem from './TravelbookListItem'

import logo from '../../logo.svg';

import { setTravelbooks } from '../../actions/index'
import { TRAVELSTEPS_SERVER_URL } from '../../commons.js'


const TravelbookList = (props) => {

    const { travelbooks, setTravelbooks } = props
    
    // chargement des carnets de voyage
    React.useEffect(() => {

        const fetchData = async () => {
            const data = new FormData()
            data.append('action', 'get-all-travelbooks')

            axios.post( TRAVELSTEPS_SERVER_URL, data )
            .then(function (response) {
                // mise à jour de la liste des carnets de voyages
                setTravelbooks(response.data)
            })
        }

        fetchData()

    }, [setTravelbooks])

    // affichage des carnets dans une scrolllist
    if (!travelbooks.length) return ( <div className="loading"><p><strong>Chargement des carnets de voyage ... Patience</strong></p><img src={logo} className="App-logo" alt="logo" /></div> )
    else return (
        <div className="travelbooks-container">
            {travelbooks.map((travelbook) => {
                return <TravelbookListItem key={travelbook.id} travelbook={travelbook} />
            })}
        </div>
    )
}

// Redux: mapping action creators - setTravelbooks
// Mise à jour de la liste de carnets
function mapDispatchToProps(dispatch){
    return bindActionCreators({setTravelbooks:setTravelbooks},dispatch)
}

const mapStateToProps = state => {
    return { 
      travelbooks: state.travelbooks
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TravelbookList)