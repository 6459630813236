import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Carousel from 'react-bootstrap/Carousel'

import Note from '../../components/Note'

// actions redux
import { setCurrentStep } from '../../actions/index'
import { setCurrentNote } from '../../actions/index'


// Affichage du carousel des notes

const NotesCarousel = (props) => {

  // variables d'état gloables
  const { steps, currentStep, setCurrentStep, noteIndexes, currentNote, selectedStepId } = props

  // index de la note dans le carousel - par défaut la dernière
  const index0 = (noteIndexes.length) ? noteIndexes.length - 1 : 0

  const [index, setIndex] = React.useState(index0)


  // changement de note via les fleches droite/gauche du carousel de notes
  const handleSelect = (selectedIndex, e) => {

    // maj de l'index du carousel
    setIndex(selectedIndex)

    // on regarde si l'item affiche correspond à une note d'une nouvelle étape
    const newStepId = noteIndexes[selectedIndex][1]

    if (newStepId !== currentStep.id) {
      // nouvelle étape courante
      const newStep = steps.find(step => step.id === newStepId)
      if (newStep) setCurrentStep(newStep)
    }
  }

  
  // mise à jour de la position du carousel suite à la modification de la note courante
  React.useEffect(() => {

    const moveToCurrentNote = async () => {
      const newIndex = currentStep.firstnote + currentNote.number - 1  // index de la note
      setIndex(newIndex)
    }

    if (currentStep && currentNote) moveToCurrentNote()

    // eslint-disable-next-line
  }, [currentNote])


  // mise à jour de la position du carousel en fonction du marker etape désigné sur la carte
  // des que selectedStepId est modifiée le carousel se positionne la premiere note de l'étape courante
  React.useEffect(() => {

    const updateSlider = async () => {
      const newIndex = currentStep.firstnote  // position sur la premiere note de l'étape
      setIndex(newIndex)
    }

    if (currentStep) updateSlider()

    // eslint-disable-next-line
  }, [selectedStepId])


  // affichage du carousel de notes - depend du composant Carousel de react-bootstrap
  if (steps.length) {
    return (
      <div id="steps-slider" className="notes-slider-container">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          interval={null}  // null ou 5000
        >
          {steps.map((step) => {
            if (step.notes.length) {
              return step.notes.map((note) => {
                return (
                  <Carousel.Item>
                    <Note key={`note-${note.id}`} stepId={step.id} note={note} />
                  </Carousel.Item>
                )
              })
            }
            else {
              return ('')
            }
          }
          )}
        </Carousel>
      </div>
    )
  }
  else return ('')
}

// Redux: mapping action creators 
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setCurrentStep: setCurrentStep, // etape courante
    setCurrenNote: setCurrentNote   // note courante
  }, dispatch)
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    steps: state.steps,
    currentStep: state.currentStep,         // etape courante
    currentNote: state.currentNote,         // note courante
    noteIndexes: state.noteIndexes,         // tableau des notes avec indexes vers la note et l'étape
    selectedStepId: state.selectedStepId    // marker etape selectionne sur la carte
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesCarousel)