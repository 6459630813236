import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

// Carnet de voyage courant
import TravelbookNavBar from '../../components/navbars/TravelbookNavBar'
// Etape courante
import StepNavBar from '../../components/navbars/StepNavBar'
// Note (DropUp menu)
import StepsSlider from '../../components/slider/StepsSlider'
// Note (DropUp menu)
import NoteNavBar from '../../components/navbars/NoteNavBar'
// liste des notes
import NotesViewer from './NotesViewer'

const TravelPanel = (props) => {

  // Container Panneau
  const { currentTravelbook, currentStep, steps } = props

  const [fontSize, setFontSize] = useState(null);

  // mise en place de la taille de la police en fonction de la largeur du panneau
  useEffect(() => {
    const resize_ob = new ResizeObserver(function (entries) {
      // since we are observing only a single element, so we access the first element in entries array
      let rect = entries[0].contentRect;

      // mise à jour de la taille de la police en fonction de la largeur
      let width = rect.width;
      let newSize

      if (width < 240) {
        newSize = 6
      }
      else if ((width > 240) && (width < 360)) { 
        newSize = 10
      }
      else if ((width > 360) && (width < 480)) {
        newSize = 12
      }
      else if (width > 480) {
        newSize = 14
      }

      if (fontSize !== newSize) {
        setFontSize(newSize)
      }
    });

    resize_ob.observe(document.querySelector("#panel"));
  }, [fontSize])


  return (
    <div id="panel" style={{ fontSize: `${fontSize}px` }}>
      <TravelbookNavBar currentTravelbook={currentTravelbook}></TravelbookNavBar>
      <StepNavBar currentTravelbook={currentTravelbook} currentStep={currentStep}></StepNavBar>
      <NoteNavBar />
      <StepsSlider />
      <NotesViewer steps={steps}></NotesViewer>
    </div>
  )
}

// Redux: mapping des props depuis le store
const mapStateToProps = state => {
  return {
    currentTravelbook: state.currentTravelbook,
    currentStep: state.currentStep,
    steps: state.steps
  }
}

export default connect(mapStateToProps)(TravelPanel)