import React, { useState, useRef, useEffect } from 'react';
import "./DropDownMenu.scss";

const DropDownMenu = (props) => {
	const dropdownRef = useRef(null);
	const [isActive, setIsActive] = useState(false);
	const onClick = () => setIsActive(!isActive);

	const defaultOptions = {
		iconStyle: 'menu-light-icon',
		icon: 'fas fa-bars',            // icone menu
		buttonVisibility: true
	}
	// mise à jour des options
	const options = { ...defaultOptions, ...props.options }
	const { iconStyle, icon, buttonVisibility } = options

	useEffect(() => {
		const pageClickEvent = (e) => {
			// If the active element exists and is clicked outside of
			if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
				setIsActive(!isActive);
			}
		};

		// If the item is active (ie open) then listen for clicks
		if (isActive) {
			window.addEventListener('click', pageClickEvent);
		}

		return () => {
			window.removeEventListener('click', pageClickEvent);
		}

	}, [isActive]);

	// si le bouton est visible
	if (buttonVisibility) {
		return (
			<div className="menu-container">
				<button type="button" className="menu-trigger"><span className={iconStyle} onClick={onClick}><i className={icon}></i></span></button>
				<nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
					<ul>
						{props.children}
					</ul>
				</nav>
			</div>
		)
	}
	else return ('')

};

export default DropDownMenu;