import React, { useState, useRef } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// actions redux
import { setSelectedStepId } from '../../actions/index'


const StepsSlider = (props) => {

    const { currentTravelbook, currentStep, steps, setSelectedStepId } = props

    const nbSteps = steps.length

    const sliderRef = useRef()

    let value0 = 0
    if (currentTravelbook.islast) {
        value0 = nbSteps
    }

    const [value, setValue] = useState(value0)

    // mise à jour de l'index et donc de l'étape courante des qu'on modifie le slider
    const onChange = (value) => {
        setValue(value)
        console.log(value)
        if ((value >= 0) && (value < nbSteps)) {
            // positionnement sur l'étape
            setSelectedStepId(steps[value].id)
        }
    }

    // Reaffichage du slider des que l'étape courante change
    React.useEffect(() => {

        if (currentStep) {
            // update slider position
            const index = steps.findIndex(step => step.id === currentStep.id)
            setValue(index)
        }

    }, [currentStep, steps])


    return (
        <div className="steps-slider-container">
            <Slider
                ref= {sliderRef}
                min={0}
                max={nbSteps - 1}
                step="1"
                defaultValue={value0}
                value={value}
                onChange={onChange}
                onAfterChange={onChange}
                trackStyle={{ backgroundColor: 'lightgray', height: '0.2em' }}
                railStyle={{ backgroundColor: 'lightgray', height: '0.2em' }}
                handleStyle={{ border: 'solid 0.2em lightgray', backgroundColor: 'white', width: '0.8em', height: '0.8em', marginTop: '-0.3em' }}
            />
        </div>
    );
};

// Redux: mapping action creators 
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedStepId: setSelectedStepId,  // etape sélectionnée sur la carte
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentTravelbook: state.currentTravelbook,
        currentStep: state.currentStep,
        steps: state.steps,
        selectedStepId: state.selectedStepId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepsSlider)