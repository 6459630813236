import './HomePage.scss';

import React from "react"

import HomeNavBar from "../../components/navbars/HomeNavBar";
import TravelbookList from "./TravelbookList";

/* Page d'accueil Travelsteps */
const HomePage = () => {
    
    return (
        <>
            <HomeNavBar />
            <TravelbookList />
        </>
    )
}

export default HomePage