import React  from 'react'

import DropUpButton from "../navigation/DropUpButton";


const CommentNavBar = (props) => {

  const { note, stepId } = props

  return (
    <div className="comment-navbar-container">
      <div className="comment-container">
        <button type="button" className="comment-button"><span className="comment-icon"><i className="far fa-comment"></i></span></button>
      </div>
      <div className="navbar-container">
        <DropUpButton note={note} stepId={stepId} />
      </div>
    </div>
  )
}

export default CommentNavBar