import React from 'react'
import { Link } from "@reach/router"

import { IMAGE_BASE_URL } from '../../commons'
import { dateFr } from '../../utils'


const TravelbookListItem = (props) => {

    const { travelbook } = props

    const displayImage = (travelbook) => {
        if (travelbook.image) return (<img className="card-img-bottom" src={`${IMAGE_BASE_URL}/${travelbook.slug}/image/${travelbook.image}`} alt="" />)
        else return ('')
    }

    const displayDates = (travelbook) => {
        let msg = ''
        if ((travelbook.startdate) && (travelbook.enddate)) {
            const sdate = new Date(travelbook.startdate)
            const edate = new Date(travelbook.enddate)
            msg = 'du ' + dateFr(sdate, 'sansjour') + ' au ' + dateFr(edate, 'sansjour')
        }
        else if (travelbook.startdate) {
            const sdate = new Date(travelbook.startdate)
            msg = dateFr(sdate)
        }
        return (<p className="card-text">{msg}</p>)
    }

    return (
        <div className="card travelbook-item">
            <Link to={`/${travelbook.slug}`}>
                <div className="card-body">
                    <h2>{travelbook.name}</h2>
                    <p className="card-text">{travelbook.description}</p>
                    {displayDates(travelbook)}
                </div>
                {displayImage(travelbook)}
            </Link>
        </div>
    )
}

export default TravelbookListItem