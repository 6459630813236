import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import SplitPaneContext from "./SplitPaneContext";

const SplitPane = ({ children, ...props }) => {

  // Direction du container
  const [direction, setDirection] = useState('vertical');

  // Changement de dimension du container
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Hauteur, largeur du container topleft
  const [clientHeight, setClientHeight] = useState(null);
  const [clientWidth, setClientWidth] = useState(null);

  // position du Divider
  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);

  // vérifie si la taille d'ecran > 768px de large
  const mql = window.matchMedia('(min-width: 768px)');

  // Changement de taille d'ecran
  const onScreenChange = (e) => {
    if (e.matches) {
      // vient de passer a une taille > 768px
      if (direction === 'vertical') {
        // passage a horizontal
        setDirection('horizontal');
      }
    }
    else {
      // vient de passer a une taille < 768px
      if (direction === 'horizontal') {
        // passage a vertical
        setDirection('vertical');
      }
    }
  };

  // initialisation de la direction selon la taille d'ecran
  onScreenChange(mql);

  // ajout de l'event listener 
  mql.addEventListener('change', onScreenChange, false);


  // Position du Divider
  const onMouseHoldDown = (e) => {
    // stop la propagation de l'event
    if (e.cancelable) e.preventDefault();
    // event de type souris ou tactile
    if (e.type === 'mousedown') {
      // position de la souris
      xDividerPos.current = e.clientX;
      yDividerPos.current = e.clientY;
    }
    else if (e.type === 'touchstart') {
      // position du stylet
      xDividerPos.current = e.touches[0].clientX;
      yDividerPos.current = e.touches[0].clientY;
    }
  };

  // Remise à zéro de la position du Divider
  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  // Deplacement de la souris
  const onMouseHoldMove = (e) => {

    // stop la propagation de l'event
    if (e.cancelable) e.preventDefault();

    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }

    let dx, dy;
    // Calcul de la distance parcourue par la souris ou le stylet
    if (e.type === 'mousemove') {
      // souris
      dx = e.clientX - xDividerPos.current;
      dy = e.clientY - yDividerPos.current;
      yDividerPos.current = e.clientY;
      xDividerPos.current = e.clientX;
    }
    else if (e.type === 'touchmove') {
      // tactile
      dx = e.touches[0].clientX - xDividerPos.current;
      dy = e.touches[0].clientY - yDividerPos.current;
      yDividerPos.current = e.touches[0].clientY;
      xDividerPos.current = e.touches[0].clientX;
    }

    switch (direction) {
      case 'vertical':
        setClientHeight(clientHeight + dy);
        break;
      case 'horizontal':
      default:
        setClientWidth(clientWidth - dx);
        break;
    }
  };

  useEffect(() => {
    // souris
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);
    // ecran tactile
    document.addEventListener("touchend", onMouseHoldUp);
    document.addEventListener("touchmove", onMouseHoldMove);

    return () => {
      // souris
      document.removeEventListener("mouseup", onMouseHoldUp);
      document.removeEventListener("mousemove", onMouseHoldMove);
      // ecran tactile
      document.removeEventListener("touchend", onMouseHoldUp);
      document.removeEventListener("touchmove", onMouseHoldMove);
    };
  });

  return (
    <div {...props}>
      <SplitPaneContext.Provider
        value={{
          direction,
          size,
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

// barre de division
export const Divider = (props) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return (
    <div {...props} onMouseDown={onMouseHoldDown} onTouchStart={onMouseHoldDown} >
      <div className="handle" />
    </div>
  )
};


// panneau haut-droit
export const SplitPaneTopRight = (props) => {
  const topRightRef = createRef()
  const { direction } = useContext(SplitPaneContext)
  const { size } = useContext(SplitPaneContext)
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext)
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext)

  // A chaque changement de direction on reinitialise la dimension des panneaux
  useEffect(() => {
    if ((direction === 'vertical')) {
      setClientHeight(topRightRef.current.parentNode.clientHeight * 0.3)
      setClientWidth(topRightRef.current.parentNode.clientWidth)
    }
    else {
      setClientWidth(topRightRef.current.parentNode.clientWidth * 0.5)
      setClientHeight(topRightRef.current.parentNode.clientHeight)
    }
    // eslint-disable-next-line
  }, [direction]);

  useEffect(() => {
    if ((direction === 'vertical')) {
      setClientHeight(size[1] * 0.3)
      setClientWidth(size[0])
    }
    else {
      setClientWidth(size[0] * 0.5)
      setClientHeight(size[1])
    }
    // eslint-disable-next-line
  }, [size]);

  // Initialisation / Mise à jour de la hauteur du panneau TopRight
  // Application de l'effet que si clientHeight a changé
  useEffect(() => {
    topRightRef.current.style.minHeight = clientHeight + "px";
    topRightRef.current.style.maxHeight = clientHeight + "px";
    // eslint-disable-next-line
  }, [clientHeight]);

  // Initialisation / Mise à jour de la largeur du panneau TopRight
  // Application de l'effet que si clientWidth a changé
  useEffect(() => {
    topRightRef.current.style.minWidth = clientWidth + "px";
    topRightRef.current.style.maxWidth = clientWidth + "px";
    // eslint-disable-next-line
  }, [clientWidth]);

  return (
    <div {...props} className="topright-container" ref={topRightRef}>
      {props.children}
    </div>
  );
};

// Panneau bas-gauche
export const SplitPaneBottomLeft = (props) => {

  return (
    <div {...props} className="bottomleft-container">
      {props.children}
    </div>
  );
};

export default SplitPane;
