import React, { Suspense, lazy } from "react"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// action redux nom du menu
import { setMenuName } from '../../actions/index'

import DropUpMenu from "../navigation/DropUpMenu"

// Formulaires des sous menus
//import Forms from "../forms/Forms"
const Forms = lazy(() => import('../forms/Forms'));

const NoteNavBar = (props) => {

    // edition d'une note (add / update / delete)
    const { menuName, setMenuName } = props

    // mise à jour du nom de menu 
    const onClick = (e, label) => {
        if (label === menuName) setMenuName('')  // fermeture du menu suite a 2 click sur le meme item de menu
        else setMenuName(label)
    }

    return (
        <div className="note-navbar-container">
            <div className="navbar-container">
                <DropUpMenu>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'update-note')}>Modification de la note</button></li>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'delete-note')}>Suppression de la note</button></li>
                    <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'add-note')}>Ajout d'une note</button></li>
                </DropUpMenu>
                <Suspense fallback={<div>Chargement...</div>}>
                <Forms />
            </Suspense>
            </div>
        </div>
    )
}

// Redux: mapping action creators - setTracks
// Mise à jour du nom de menu à afficher
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setMenuName: setMenuName
    }, dispatch)
}

const mapStateToProps = state => {
    return {
        currentNote: state.currentNote,
        menuName: state.menuName
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteNavBar)