import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// action redux nom du menu
import { setMenuName } from '../../actions/index'

import DropDownMenu from "../navigation/DropDownMenu";

const StepNavBar = (props) => {

  // étape à éditer (add / update / delete)
  const { currentStep, menuName, setMenuName } = props

  // ajouter la première note 
  const displayNavItem = (onClick, link, label, notes) => {
    if (notes.length > 0) return ('')
    // affichage de l'item de menu 
    else return (<li><button type="button" className="link-button" onClick={(e) => onClick(e, link)}>{label}</button></li>)
  }

  if (currentStep) {

    let tdate = ''
    if (currentStep.eventdate) {
      let edate = new Date(currentStep.eventdate)
      const dateTimeFormat = new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long', day: '2-digit' })
      const [{ value: day }, , { value: month }, , { value: year }] = dateTimeFormat.formatToParts(edate)
      tdate = ` - ${day} ${month} ${year}`
    }

    // mise à jour du nom de menu 
    const onClick = (e, label) => {
      if (label === menuName) setMenuName('')  // fermeture du menu suite a 2 click sur le meme item de menu
      else setMenuName(label)
    }

    return (
      <div className="step-navbar-container">
        <div className="title">{currentStep.title} <span className="tdate">{tdate}</span></div>
        <div className="navbar-container">
          <DropDownMenu>
            <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'update-step')}>Modification de l'étape</button></li>
            <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'delete-step')}>Suppression de l'étape</button></li>
            <li><button type="button" className="link-button" onClick={(e) => onClick(e, 'add-step')}>Ajout d'une étape</button></li>
            {displayNavItem(onClick, 'add-first-note', "Ajout de la première note", currentStep.notes)}
          </DropDownMenu>
        </div>
      </div>
    )
  }
  else return ('')

}

// Redux: mapping action creators - setTracks
// Mise à jour du nom de menu à afficher
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setMenuName: setMenuName
  }, dispatch)
}

const mapStateToProps = state => {
  return {
    currentStep: state.currentStep,
    menuName: state.menuName
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepNavBar)